.react-datepicker-popper {
  z-index: 9999;
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}
.react-datepicker__header__dropdown {
  margin-top: 2px;
  font-size: 12px;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border: none;
}

.react-datepicker {
  font-family: inherit;
  color: #333333;
  border: none;
  box-shadow: 0 5px 25px rgba(12, 12, 12, 0.2);
  padding: 13px 15px 15px;
}

.react-datepicker__header {
  background-color: #ffffff;
  border: none;
}

.react-datepicker__current-month {
  color: #333333;
}

.react-datepicker__navigation {
  top: 20px;
}

.react-datepicker__time-container--with-today-button {
  border: none;
}

.react-datepicker__day-name {
  color: #999999;
  font-size: 10px;
}

.react-datepicker__day {
  color: #333333;
  background-color: #f7f7f7;
  font-size: 12px;
  border-radius: 50%;
  font-weight: 100;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  color: #ffffff;
  background-color: #0fafa9;
  opacity: 0.8;
}


.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  color: #cccccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  color: #cccccc;  
  background-color: #f7f7f7;
  opacity: 1;
}

.react-datepicker__day--today {
  color: #0fafa9;
  border: 1px solid #0fafa9;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  color: #ffffff;
  background-color: #0fafa9;
}

.react-datepicker__day--selected:hover {
  border-radius: 50%;
  color: #ffffff;
  background-color: #0fafa9;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50%;
  color: #ffffff;
  background-color: #0fafa9;
}

.react-datepicker__year-read-view--down-arrow {
  top: 2px;
}

.react-datepicker__year-dropdown {
  background-color: #ffffff;
  box-shadow: 0 5px 25px rgba(12, 12, 12, 0.2);
  border: none;
}

.react-datepicker__year-option:hover {
  background-color: #0fafa9;
  color: #ffffff;
}

.react-datepicker__navigation--years-upcoming {
  top: -2px;
}

.react-datepicker__navigation--years-previous {
  top: 2px;
}

.react-datepicker__navigation {
  height: 4px;
  width: 4px;
}
