.bubble-chart-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
}
.node {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  font-family: 'sans-serif';
  font-size: 12px;
  text-align: center;
  box-sizing: border-box;
  padding: 2%;
  color: #ffffff;
  overflow: hidden;
  cursor: pointer;
}
.node:active {
  cursor:grabbing;
}
.node-icon {
  width: 70%;
  object-fit: contain;
}